import { useTranslation } from "react-i18next";

import Card from "../../components/Card/Card";
import { Link } from "react-router-dom";

const Companies = () => {
    const { t } = useTranslation();

    const textStyle = {
        fontSize: "20px"
    };

    return (
        <div className="container px-4 py-5" id="featured-3">
            <h2 className="pb-2 mb-5 border-bottom">{t('companiesSection.header1')}</h2>

            <p style={textStyle}>{t('companiesSection.content1')}</p>
            <p style={textStyle}>{t('companiesSection.content2')}</p>


            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                <Card
                    title={t('companiesSection.workAndResidencePermits.title')}
                    description={t('companiesSection.workAndResidencePermits.content')}
                    iconClass="bi bi-person-vcard"
                    url="work-and-residence-permits"
                />
                <Card
                    title={t('companiesSection.declarationOfEmploymentForForeigner.title')}
                    description={t('companiesSection.declarationOfEmploymentForForeigner.content')}
                    iconClass="bi bi-tools"
                    url="declaration-of-employment-for-foreigner"
                />
                <Card
                    title={t('companiesSection.peselSupport.title')}
                    description={t('companiesSection.peselSupport.content')}
                    iconClass="bi bi-file-text"
                    url="pesel-support"
                />
                <Card
                    title={t('companiesSection.drivingLicenseExchange.title')}
                    description={t('companiesSection.drivingLicenseExchange.content')}
                    iconClass="bi bi-car-front"
                    url="driving-license-exchange"
                />
                <Card
                    title={t('companiesSection.permanentResidenceAndEUPermit.title')}
                    description={t('companiesSection.permanentResidenceAndEUPermit.content')}
                    iconClass="bi bi-house-heart"
                    url="permanent-residence-and-eu-permit"
                />
                <Card
                    title={t('companiesSection.ongoingCaseSupport.title')}
                    description={t('companiesSection.ongoingCaseSupport.content')}
                    iconClass="bi bi-chevron-double-right"
                    url="ongoing-case-support"
                />
            </div>

            <center>
                <Link to={"/contact"} style={textStyle}><b>{t('companiesSection.header2')}</b></Link>
                <p style={textStyle}>{t('companiesSection.content3')}</p>
            </center>

        </div>
    );
};

export default Companies;