const Footer = () => {
    return (
        <footer className="d-flex flex-column flex-md-row py-3 gap-1 gap-md-0 border-top bg-body-tertiary" style={{ fontSize: "14px" }} >
            <div className="col-12 col-md-6 d-flex justify-content-md-end">
                <span className="mx-4-md px-2 my-auto text-body-secondary">© 2024 EASYPERMIT sp. z o.o.</span>
            </div>
            <div className="col-12 col-md-6 d-flex flex-column flex-md-row align-items-start text-body-secondary mr-5 gap-md-2">
                <span className="d-flex flex-row">
                    <i className="bi bi-envelope-at text-primary px-2" style={{ fontSize: "20px" }}></i><p className="my-auto py-auto" style={{ verticalAlign: "middle" }}>biuro@easypermit.pl</p>
                </span>
                <span className="d-flex flex-row">
                    <i className="bi bi-telephone-inbound text-primary px-2" style={{ fontSize: "20px" }}></i><p className="my-auto"> +48 794 762 151</p>
                </span>
            </div>
        </footer>
    )
};

export default Footer;