import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Layout from './components/Layout/Layout';
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Root from "./pages/Root/Root";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Companies from "./pages/Companies/Companies";
import Foreigners from "./pages/Foreigners/Foreigners";
import ContactForm from "./components/ContactForm/ContactForm";

// Foreigners subpages
import TemporaryResidenceCard from "./pages/Foreigners/subpages/TemporaryResidenceCard";
import PermanentResidencePermit from "./pages/Foreigners/subpages/PermanentResidencePermit";
import LongTermEUResidentPermit from "./pages/Foreigners/subpages/LongTermEUResidentPermit";
import InvitationForForeigner from "./pages/Foreigners/subpages/InvitationForForeigner";
import DeclarationOfEmployment from "./pages/Foreigners/subpages/DeclarationOfEmployment";
import DrivingLicense from "./pages/Foreigners/subpages/DrivingLicense";
import Pesel from "./pages/Foreigners/subpages/Pesel";
import CarRegistration from "./pages/Foreigners/subpages/CarRegistration";
import OngoingCases from "./pages/Foreigners/subpages/OngoingCases";
import WorkPermit from "./pages/Foreigners/subpages/WorkPermit";

// Companies subpgaes
import DeclarationOfEmploymentForForeigner from "./pages/Companies/subpages/DeclarationOfEmploymentForForeigner";
import DrivingLicenseExchange from "./pages/Companies/subpages/DrivingLicenseExchange";
import OngoingCaseSupport from "./pages/Companies/subpages/OngoingCaseSupport";
import PermanentResidenceAndEUPermit from "./pages/Companies/subpages/PermanentResidenceAndEUPermit";
import PeselSupport from "./pages/Companies/subpages/PeselSupport";
import WorkAndResidencePermits from "./pages/Companies/subpages/WorkAndResidencePermits";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <About />,
        },
        //foreigners
        {
          path: "/foreigners",
          element: <Foreigners />,
        },
        {
          path: "foreigners/temporary-residence-card",
          element: <TemporaryResidenceCard />,
        },
        {
          path: "foreigners/permanent-residence-permit",
          element: <PermanentResidencePermit />,
        },
        {
          path: "foreigners/long-term-eu-resident-permit",
          element: <LongTermEUResidentPermit />,
        },
        {
          path: "foreigners/invitation-for-foreigner",
          element: <InvitationForForeigner />,
        },
        {
          path: "foreigners/work-permit",
          element: <WorkPermit />,
        },
        {
          path: "foreigners/declaration-of-employment",
          element: <DeclarationOfEmployment />,
        },
        {
          path: "foreigners/driving-license",
          element: <DrivingLicense />,
        },
        {
          path: "foreigners/pesel",
          element: <Pesel />,
        },
        {
          path: "foreigners/car-registration",
          element: <CarRegistration />,
        },
        {
          path: "foreigners/ongoing-cases",
          element: <OngoingCases />,
        },
        // companiees
        {
          path: "/companies",
          element: <Companies />,
        },
        {
          path: "companies/declaration-of-employment-for-foreigner",
          element: <DeclarationOfEmploymentForForeigner />,
        },
        {
          path: "companies/driving-license-exchange",
          element: <DrivingLicenseExchange />,
        },
        {
          path: "companies/ongoing-case-support",
          element: <OngoingCaseSupport />,
        },
        {
          path: "companies/permanent-residence-and-eu-permit",
          element: <PermanentResidenceAndEUPermit />,
        },
        {
          path: "companies/pesel-support",
          element: <PeselSupport />,
        },
        {
          path: "companies/work-and-residence-permits",
          element: <WorkAndResidencePermits />,
        },
        {
          path: "/contact",
          element: <ContactForm />,
        },

      ],
    }
  ]);

  return (
    <RouterProvider router={router}>
      <Layout />
    </RouterProvider>
  );
}

export default App;
