import React from 'react';
import { useTranslation } from 'react-i18next';

const DrivingLicenseExchange = () => {
    const { t } = useTranslation();

    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <article>
                        <h2 className="py-2">{t('companiesSection.drivingLicenseExchange.subpages.header1')}</h2>
                        <hr />
                        <section className="py-3">
                            <p>{t('companiesSection.drivingLicenseExchange.subpages.section1.content')}</p>
                        </section>
                        <section className="py-3">
                            <h4>{t('companiesSection.drivingLicenseExchange.subpages.section2.sectionTitle1')}</h4>
                            <ul>
                                <li>{t('companiesSection.drivingLicenseExchange.subpages.section2.ul1Item1')}</li>
                                <li>{t('companiesSection.drivingLicenseExchange.subpages.section2.ul1Item2')}</li>
                                <li>{t('companiesSection.drivingLicenseExchange.subpages.section2.ul1Item3')}</li>
                            </ul>
                        </section>
                        <div className="alert alert-success mt-4" role="alert">
                            <h5 className="alert-heading">{t('companiesSection.drivingLicenseExchange.subpages.alertSection.alertTitle')}</h5>
                            <p>{t('companiesSection.drivingLicenseExchange.subpages.alertSection.alertContent')}</p>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    );
};

export default DrivingLicenseExchange;
