import { useTranslation } from "react-i18next";

import classes from "./Hero.module.css";

const Hero = (props) => {
    const { t } = useTranslation();

    const style = {
        backgroundImage: `url(${require('./../../assets/banner.jpg')})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "550px"
    };

    const maskStyle = {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        height: "100%",
        width: "100%"
    };

    const textStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "180px",
        paddingLeft: "18px",
        paddingRight: "18px",

    };

    const buttonStyle = {
        width: "230px",
        alignSelf: "center",
    };

    return (
        <div className="text-center bg-image rounded-3" style={style}>
            <div className="mask" style={maskStyle}>
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="text-white" style={textStyle}>
                        <div className={classes.header}>
                            <h1>EASY<span>PERMIT</span></h1>
                            <h4 className="fw-light">{t('heroSection.subHeader')}</h4>
                        </div>
                        <button type="button" className="btn btn-outline-light btn-lg px-4" style={buttonStyle} onClick={(props.handleScroll)}>{t('heroSection.button')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Hero; 