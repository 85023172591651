import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import classes from "./Card.module.css";

const Card = (props) => {
    const { t } = useTranslation();

    const style = {
        minHeight: "220px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly"
    };

    const iconStyle = {
        fontSize: "32px",
        marginRight: "15px",
        color: "#0d6efd"
    };

    const cardClass = ["card-body", [classes.card]].join(" ");

    return (
        <div className="px-2">
            <div className="card shadow border">
                <div className={cardClass}>
                    <div className="feature col d-flex flex-column" style={style}>
                        <div className="d-flex mb-1">
                            <i className={props.iconClass} style={iconStyle}></i>
                            <h3 className="fs-5 my-auto">{props.title}</h3>
                        </div>
                        <p>{props.description}</p>
                        <div>
                            <Link to={props.url} className="btn btn-primary" style={{ minWidth: "30%" }}>{t('companiesSection.button')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card; 