import { useTranslation } from "react-i18next";

import Card from "../../components/Card/Card";

const Foreigners = () => {
    const { t } = useTranslation();

    return (
        <div className="container px-4 py-5" id="featured-3">
            <h2 className="pb-2 mb-5 border-bottom text-center">{t('foreignersSection.header1')}</h2>

            <h3>{t('foreignersSection.header2')}</h3>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                <Card
                    title={t('foreignersSection.temporaryResidenceCard.title')}
                    description={t('foreignersSection.temporaryResidenceCard.content')}
                    iconClass="bi bi-person-vcard"
                    url="temporary-residence-card"
                />
                <Card
                    title={t('foreignersSection.permanentResidencePermit.title')}
                    description={t('foreignersSection.permanentResidencePermit.content')}
                    iconClass="bi bi-house-heart"
                    url="permanent-residence-permit"
                />
                <Card
                    title={t('foreignersSection.longTermEUResidentPermit.title')}
                    description={t('foreignersSection.longTermEUResidentPermit.content')}
                    iconClass="bi bi-luggage"
                    url="long-term-eu-resident-permit"
                />
                <Card
                    title={t('foreignersSection.invitationForForeigner.title')}
                    description={t('foreignersSection.invitationForForeigner.content')}
                    iconClass="bi bi-globe"
                    url="invitation-for-foreigner"
                />
            </div>

            <h3>{t('foreignersSection.header3')}</h3>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                <Card
                    title={t('foreignersSection.declarationOfEmployment.title')}
                    description={t('foreignersSection.declarationOfEmployment.content')}
                    iconClass="bi bi-file-text"
                    url="declaration-of-employment"
                />
                <Card
                    title={t('foreignersSection.workPermit.title')}
                    description={t('foreignersSection.workPermit.content')}
                    iconClass="bi bi-file-text"
                    url="work-permit"
                />
            </div>

            <h3>{t('foreignersSection.header4')}</h3>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                <Card
                    title={t('foreignersSection.drivingLicense.title')}
                    description={t('foreignersSection.drivingLicense.content')}
                    iconClass="bi bi-person-vcard"
                    url="driving-license"
                />
                <Card
                    title={t('foreignersSection.carRegistration.title')}
                    description={t('foreignersSection.carRegistration.content')}
                    iconClass="bi bi-car-front"
                    url="car-registration"
                />
                <Card
                    title={t('foreignersSection.pesel.title')}
                    description={t('foreignersSection.pesel.content')}
                    iconClass="bi bi-file-text"
                    url="pesel"
                />
                <Card
                    title={t('foreignersSection.ongoingCases.title')}
                    description={t('foreignersSection.ongoingCases.content')}
                    iconClass="bi bi-chevron-double-right"
                    url="ongoing-cases"
                />
            </div>

        </div>
    );
};

export default Foreigners;